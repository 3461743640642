import React, { useCallback, useState } from "react";
import moment from 'moment-timezone';
import { Button, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import UseInventory from "./UseInventory";

export default function StockLevels({ items, sid, allowInventoryUse, refresh }) {
	const navigate = useNavigate();
	const [showUseInventory, setShowUseInventory] = useState(false);
	const [item, setItem] = useState({iid:'', units_remaining: 0});

	const calculateStockColor = (stockLevel) => {
		if (stockLevel > 66) {
			return "green";
		} else if (stockLevel > 33) {
			return "gold";
		} else {
			return "red";
		}
	};

	const useInventoryAction = useCallback(({ iid, units_remaining }) => {
		setShowUseInventory(true);
		setItem({ iid, units_remaining });
	}, [sid]);

	return (
		<div>
			{items.map((item, key) => {
				const stockLevel = Math.round((((item.units_remaining / item.units) * 100) + Number.EPSILON) * 100) / 100;
				return (
					<div className="flex" style={{ alignItems: 'center' }} key={key}>
						{allowInventoryUse && <div className="mr-2"><Button icon="syringe" size="mini" className="cosm-button tertiary" onClick={() => useInventoryAction(item)}/></div> }
						<div className="mr-2"><Button icon="book" size="mini" className="cosm-button quaternary" onClick={() => navigate(`/drugbook/${item.pid}/${item.iid}`)}/></div>
						<div className="mb-2" style={{ minWidth: 400 }}>
							<p className="mb-0" style={{fontSize: 16}}><b>{item.batch_number}</b> <small>{moment(item.created_at).format('DD/MM/YY hh:mmA')}</small></p>
							<div style={{ backgroundColor: 'transparent', height: "20px", width: '100%', marginBottom: "5px", position: 'relative' }}>
								<div style={{ backgroundColor: calculateStockColor(stockLevel), height: "20px", width: `${stockLevel}%`, position: 'absolute', left: 0, top: 0 }}></div>
								<div style={{ backgroundColor: 'transparent', height: "20px", width: `${stockLevel}%`, minWidth: 140, marginBottom: "5px", position: 'relative' }}>
									<span className="absolute" style={{ right: 3, color: stockLevel < 66 ? 'black' : 'white', fontSize: '12px', top: 0 }}>
										<span className="mr-2">({item.units_remaining})</span>{stockLevel}%
									</span>
								</div>
							</div>
						</div>
					</div>
				)
			})}
			<UseInventory iid={item.iid} units_remaining={item.units_remaining} isOpen={showUseInventory} onClose={()=>setShowUseInventory(false)} update={refresh} />
		</div>
	);
}