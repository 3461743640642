import React, { useState } from 'react';
import { Container, Message } from 'semantic-ui-react';
import { useUser } from '../../xAppLib/Hooks';
import { useAsync } from '../../xAppLib/Hooks/useAsync';
import audit_model from '../../cosmetics/audit/model';
import { Link } from 'react-router-dom';

const EXPIRY_SNOOZE = 1000 * 60 * 60 * 24; // 1 day

export default function AuditExpirationBanner() {
    const user = useUser();
    const [dismissed, setDismissed] = useState([]);
    
    const {
        loading,
        error,
        data,
    } = useAsync(async () => user.uid &&
        (user.claims.nurse || user.claims.supp || user.claims.admin || user.claims.mang) &&
        await audit_model.get_my_current_audits(), [user.uid]);

    if ((loading || error) && !data ) return null;

    const onDismiss = (audit_num) => {
        setDismissed([...dismissed, audit_num]);
        sessionStorage.setItem(
            `${audit_num}_expiration_banner_dismissed`,
            JSON.stringify({ expiry: new Date().getTime() + EXPIRY_SNOOZE })
        );
    };
    
    const checkDismissed = (audit_num) => dismissed.includes(audit_num) ||
        JSON.parse(sessionStorage.getItem(`${audit_num}_expiration_banner_dismissed`))
        ?.expiry > new Date().getTime();

    const msgs = [];
    // expired audits
    if (
        data?.practitioner?.length &&
        data?.practitioner[0].expired &&
        !checkDismissed(data?.practitioner[0].audit_num)
    ) msgs.push({
        type: 'error',
        onDismiss: () => onDismiss(data?.practitioner[0].audit_num),
        content: `Your Audit has expired on ${new Date(data?.practitioner[0].expiry_date).toLocaleDateString()}`,
        header: `Expired Practitioner Audit`
    });

    if (
        data?.clinic?.length &&
        data?.clinic[0].expired &&
        !checkDismissed(data?.clinic[0].audit_num)
    ) msgs.push({
        type: 'error',
        onDismiss: () => onDismiss(data?.clinic[0].audit_num),
        content: `Your clinic's audit has expired on ${new Date(data?.clinic[0].expiry_date).toLocaleDateString()}`,
        header: `Expired Clinic Audit`
    });

    // expiring soon audits
    if (
        data?.practitioner?.length &&
        data?.practitioner[0].expiring_soon &&
        !checkDismissed(data?.practitioner[0].audit_num)
    ) msgs.push({
        type: 'warning',
        onDismiss: () => onDismiss(data?.practitioner[0].audit_num),
        content: `Your practitioner audit will expire on ${new Date(data?.practitioner[0].expiry_date).toLocaleDateString()}`,
        header: `Practitioner Audit Expiring Soon`
    });
    if (
        data?.clinic?.length &&
        data?.clinic[0].expiring_soon &&
        !checkDismissed(data?.clinic[0].audit_num)
    ) msgs.push({
        type: 'warning',
        onDismiss: () => onDismiss(data?.clinic[0].audit_num),
        content: `Your clinic's audit will expire on ${new Date(data?.clinic[0].expiry_date).toLocaleDateString()}`,
        header: `Clinic Audit Expiring Soon`
    });

    // awaiting review
    if (
        data?.review?.length &&
        !checkDismissed('supports_banner')
    ) msgs.push({
        onDismiss: () => onDismiss('support_banner'),
        header: `Audits Pending Review`,
        content: <>There are {data?.review.length} audits awaiting review <Link to={`/cosm/audit/list?status=submitted`}>(click to view)</Link></>,
    });


    if (!msgs.length) return null;


	return (
		<Container className='wide-content' fluid>
            {msgs.map(msg => <Message
                error={msg.type === 'error'}
                warning={msg.type === 'warning'}
                icon='certificate'
                onDismiss={msg.onDismiss}
                content={msg.content}
                header={msg.header}
            />)}
		</Container>
	)
}
 
